// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const termsAndConditionsQuery = gql`
  query TermsAndConditions($locale: SiteLocale) {
    termsAndConditionsModel(locale: $locale) {
      text {
        value
      }
    }
  }
`
