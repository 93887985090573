
import { mapGetters, mapActions, mapState } from 'vuex'
import SiteFooter from '~/components/site-nav/site-footer'
import SiteHeader from '~/components/site-nav/header-navigation/site-header.vue'
import RegistrationMessage from '~/components/registration-message/registration-message.vue'
import { removeRouteNameLanguageSuffix } from '~/utils/remove-route-name-language-suffix'
import TermsAndConditionsDialog from '~/components/page-explore/terms-and-conditions-dialog'
import OfferDialog from '~/components/page-explore/offer-dialog'
import { getMetaTags } from '~/utils/get-meta-tags'

export default {
  components: {
    SiteHeader,
    SiteFooter,
    RegistrationMessage,
    TermsAndConditionsDialog,
    OfferDialog,
  },

  data() {
    return {
      dialogType: null,
    }
  },

  fetch() {
    return this.$i18nTranslations.loadTranslations()
  },

  head() {
    const slugPages = [
      'slug',
      'articles-slug',
      'lessons-slug',
      'workshops-and-webinars-slug',
    ]

    const routeName = removeRouteNameLanguageSuffix(this.$route.name)
    let addSeoAttributes = true

    if (slugPages.includes(routeName)) {
      // To prevent adding incorrect alternates
      addSeoAttributes = false
    }

    return {
      ...this.$nuxtI18nHead({ addSeoAttributes }),
      meta: getMetaTags(this.$config),
    }
  },

  computed: {
    ...mapGetters('toasts', ['items']),
    ...mapState('trader', ['hasConsented', 'hasOffering']),
    ...mapState('infoDialog', [
      'isOpen',
      'title',
      'componentName',
      'componentProps',
    ]),
  },

  watch: {
    hasConsented() {
      this.attemptSetTermsDialog()
    },

    hasOffering() {
      this.attemptSetOfferDialog()
    },
  },

  mounted() {
    this.attemptSetTermsDialog()
    this.attemptSetOfferDialog()
  },

  methods: {
    ...mapActions('toasts', ['dismissToast']),
    ...mapActions('trader', ['loadKeyFigures']),

    attemptSetTermsDialog() {
      if (this.hasConsented === false) {
        this.dialogType = 'terms'
      }
    },

    attemptSetOfferDialog() {
      if (this.hasOffering) {
        this.dialogType = 'offer'
      }
    },
  },
}
