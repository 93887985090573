const externalUrlMappings = new Map([
  [
    'privacy-statement',
    'https://support.theswex.com/%locale%/support/solutions/articles/44002220147-privacy-statement',
  ],
  [
    'cookie-statement',
    'https://support.theswex.com/%locale%/support/solutions/articles/44002220135-cookie-statement',
  ],
  [
    'terms-and-conditions',
    'https://support.theswex.com/%locale%/support/solutions/articles/44002524515-terms-conditions',
  ],
  ['faq', 'https://support.theswex.com/%locale%/support/solutions'],
  [
    'cask-trading',
    'https://support.theswex.com/%locale%/support/solutions/articles/44002220363-guarantees-with-whisky-in-cask',
  ],
  [
    'trade-with-leverage',
    'https://support.theswex.com/%locale%/support/solutions/articles/44002222905',
  ],
])

export function getExternalUrl(key, locale) {
  const url = externalUrlMappings.get(key)

  if (!url) {
    return null
  }

  return url.replace('%locale%', locale)
}
