import { set } from 'vue'
import qs from 'qs'
import createObjectHash from '~/utils/object-hash'
import assert from '~/utils/assert'
import flattenObject from '~/utils/flatten-object'

let requestTimeout = 0

const LOAD_TIMEOUT = 500

export const possibleLoadStates = {
  IDLE: 'idle',
  LOADING: 'loading',
  ERROR: 'error',
  DONE: 'done',
}

export const state = () => {
  return {
    cancelSource: null,
    config: {
      filterConfigurations: [],
      sortingOptions: [],
      viewOptions: [],
      customParamConfigurations: {},
      endpoint: null,
      suggestEndpoint: null,
      enableKeywordSearch: true,
      rowIdKeys: null,
    },
    requestData: {
      filters: {},
      page: 1,
      pageSize: 25,
      searchQuery: undefined,
      sorting: undefined,
      view: undefined,
      customParams: {},
    },
    responseData: {
      facets: {},
      collection: [],
      pagingData: {
        recordCount: 0,
      },
      metadata: {
        defaultRowTemplate: null,
      },
    },
    loadingState: possibleLoadStates.IDLE,
    loadingNewTotals: false,
  }
}

export const mutations = {
  reset(currentState) {
    Object.assign(currentState, state())
  },

  setConfig(state, config) {
    state.config = config
  },

  setInitialFilterData(state, filterData) {
    state.requestData.filters = filterData
  },

  resetCustomParamsToInitialState(state) {
    const configs = state.config.customParamConfigurations

    const customParams = Object.fromEntries(
      Object.entries(configs).map(([id, config]) => {
        return [id, config.defaultValue]
      }),
    )

    state.requestData.customParams = customParams
  },

  updateRequestData(state, data) {
    Object.assign(state.requestData, data)
  },

  updateFilterData(state, { id, value }) {
    set(state.requestData.filters, id, value)
  },

  updateResponseData(
    state,
    { pagingData, collection, facets = {}, metadata = {} },
  ) {
    assert(
      typeof pagingData === 'object',
      'Expected pagingData to be an object. Got something else',
    )
    assert(
      Array.isArray(collection),
      'Expected collection to be an array. Got something else',
    )

    Object.assign(state.responseData, {
      pagingData,
      collection: Object.freeze(collection),
      facets: Object.freeze(facets),
      metadata: Object.freeze(metadata),
    })
  },

  updateLoadingState(state, { loadingState, loadingNewTotals, cancelSource }) {
    state.loadingState = loadingState
    state.loadingNewTotals = loadingNewTotals
    state.cancelSource = Object.freeze(cancelSource)
  },
}

export const actions = {
  init({ commit, state, dispatch, getters }, { config, loadFromQuery = true }) {
    commit('reset')

    commit('setConfig', config)

    const initialFilterData = config.filterConfigurations.reduce(
      (acc, config) => ({
        ...acc,
        [config.id]: getters.filterGetDefaultValue(config.id),
      }),
      {},
    )
    commit('setInitialFilterData', initialFilterData)

    commit('resetCustomParamsToInitialState')

    if (loadFromQuery) {
      // delegate loading from query to querySyncer
      // after query read is done, postQueryRead action is dispatched. Only then we will load the data
      dispatch(
        'querySyncer/init',
        {
          initial: state.requestData,
          queryChangeAction: 'resultsView/postQueryRead',
        },
        { root: true },
      )
    } else {
      dispatch('postQueryRead', state.requestData)
    }
  },

  destroy({ dispatch, commit }) {
    dispatch('querySyncer/destroy', null, { root: true })
    dispatch('cancelPendingLoad')
    commit('reset')
  },

  setCustomParam({ commit, state, dispatch }, { name, value }) {
    const config = state.config.customParamConfigurations[name]

    assert(
      typeof config === 'object',
      `Missing customParamConfiguration for ${name}`,
    )

    commit('updateRequestData', {
      customParams: {
        ...state.requestData.customParams,
        [name]: value,
      },
      page: !config.resetsPagination ? state.requestData.page : 1,
    })
    dispatch('updateQuery')
    dispatch('loadDebounced', { loadingNewTotals: true })
  },

  clearFilters({ state, dispatch }) {
    state.config.filterConfigurations.forEach((filter) => {
      dispatch('clearFilterValue', filter.id)
    })
  },

  clearFilterValue({ commit, getters, dispatch }, id) {
    assert(
      getters.filterGetConfig(id),
      `Filter with ${id} is not configured for current view`,
    )

    const config = getters.filterGetConfig(id)

    let newValue
    if (config.clearedValue !== undefined) {
      newValue = config.clearedValue
    } else {
      newValue = config.multiValue ? [] : undefined
    }

    commit('updateFilterData', { id, value: newValue })
    commit('updateRequestData', { page: 1 })
    dispatch('updateQuery')
    dispatch('loadDebounced', { loadingNewTotals: true })
  },

  setFilterValue({ commit, getters, dispatch }, { id, value }) {
    const isMultiValue = getters.filterGetConfig(id).multiValue
    if (isMultiValue) {
      assert(
        Array.isArray(value),
        'Filter was marked multiValue and value should therefore be an array',
      )
    } else {
      assert(
        !Array.isArray(value),
        'Filter was NOT marked multiValue and value should therefore be anything other than an array',
      )
    }
    commit('updateFilterData', { id, value })
    commit('updateRequestData', { page: 1 })
    dispatch('updateQuery')
    dispatch('loadDebounced', { loadingNewTotals: true })
  },

  setEndpoint({ dispatch, state }, endpoint) {
    // init resets all state (including the cancelation token. So we have to make sure to cancel before the reset)
    dispatch('cancelPendingLoad')
    dispatch('init', {
      config: {
        ...state.config,
        endpoint,
      },
      loadFromQuery: false,
    })
  },

  cancelPendingLoad({ state }) {
    clearTimeout(requestTimeout)
    state.cancelSource?.cancel()
  },

  async load(
    { state, dispatch, commit, getters },
    { loadingNewTotals = true, silent = false } = {},
  ) {
    dispatch('cancelPendingLoad')

    const cancelSource = this.$axios.CancelToken.source()
    if (!silent) {
      commit('updateLoadingState', {
        loadingState: possibleLoadStates.LOADING,
        loadingNewTotals,
        cancelSource,
      })
    } else {
      commit('updateLoadingState', {
        cancelSource,
      })
    }

    const [url, fixedQuery] = state.config.endpoint.split('?', 2)

    const query = getters.getRequestQueryString(
      {
        includeSorting: true,
        includePagination: true,
        extraQueryParams: qs.parse(fixedQuery),
      },
      this.$i18n.locale,
    )

    try {
      const data = await this.$axios.$get(url + '?' + query, {
        cancelToken: cancelSource.token,
      })
      commit('updateLoadingState', {
        loadingState: possibleLoadStates.DONE,
        loadingNewTotals: false,
        cancelSource: null,
      })
      commit('updateResponseData', data)

      return true
    } catch (e) {
      console.error(e)

      if (!this.$axios.isCancel(e)) {
        commit('updateLoadingState', {
          loadingState: possibleLoadStates.ERROR,
          loadingNewTotals: false,
          cancelSource: null,
        })
      }

      return false
    }
  },

  async postQueryRead({ commit, getters, state, dispatch }, requestData) {
    const view = requestData.view ?? state.config.defaultView
    const sorting =
      requestData.sorting ?? getters.getDefaultSortingForView(view)

    commit('updateRequestData', {
      ...requestData,
      sorting,
      view,
    })

    const success = await dispatch('load', { loadingNewTotals: true })

    if (!success) {
      return
    }

    const defaultApiView =
      state.responseData.metadata?.defaultRowTemplate?.toLowerCase()

    // if no view was selected and a valid api view is present then we use that as the default view
    if (!requestData.view && defaultApiView) {
      const isValidView = state.config.viewOptions.some(
        (view) => view.id === defaultApiView,
      )
      if (isValidView) {
        commit('updateRequestData', {
          view: defaultApiView,
          sorting: getters.getDefaultSortingForView(defaultApiView),
        })
      }
    }
  },

  setView({ commit, getters, dispatch }, view) {
    if (view === getters.view) {
      return
    }

    const defaultSortingForNewView = getters.getDefaultSortingForView(view)

    // if sorting was changed because of the new view (very likely) then we should do a request to the server
    const changesSorting =
      defaultSortingForNewView?.id !== getters.sorting?.id ||
      defaultSortingForNewView?.sortOrder !== getters.sorting?.sortOrder

    commit('updateRequestData', {
      view,
      sorting: defaultSortingForNewView,
    })

    dispatch('updateQuery')

    if (changesSorting) {
      dispatch('loadDebounced', { loadingNewTotals: false })
    }
  },

  setPage({ commit, dispatch, getters }, page) {
    if (page === getters.page) {
      return
    }

    commit('updateRequestData', { page })
    dispatch('updateQuery')
    dispatch('loadDebounced', { loadingNewTotals: false })
  },

  setPageSize({ commit, dispatch, getters }, pageSize) {
    if (pageSize === getters.pageSize) {
      return
    }

    commit('updateRequestData', {
      pageSize,
    })

    if (getters.page > getters.pageCount) {
      commit('updateRequestData', {
        page: getters.pageCount,
      })
    }

    dispatch('updateQuery')
    dispatch('loadDebounced', { loadingNewTotals: false })
  },

  setSearchQuery({ commit, dispatch, getters }, searchQuery) {
    if (searchQuery === getters.searchQuery) {
      return
    }

    commit('updateRequestData', { searchQuery, page: 1 })
    dispatch('updateQuery')
    dispatch('loadDebounced', { loadingNewTotals: true })
  },

  setSorting({ commit, dispatch }, sorting) {
    commit('updateRequestData', { sorting })
    dispatch('updateQuery')
    dispatch('loadDebounced', { loadingNewTotals: false })
  },

  updateQuery({ dispatch, state }) {
    dispatch('querySyncer/updateQuery', state.requestData, { root: true })
  },

  loadDebounced({ dispatch }, options) {
    clearTimeout(requestTimeout)
    requestTimeout = setTimeout(() => {
      dispatch('load', options)
    }, LOAD_TIMEOUT)
  },
}

export const getters = {
  getRequestQueryString(state) {
    return function (
      {
        includeSorting = true,
        includePagination = true,
        extraQueryParams,
      } = {},
      locale,
    ) {
      const params = {
        ...extraQueryParams,
        queryKeyword: state.requestData.searchQuery,
        ...state.requestData.filters,
        ...state.requestData.customParams,
      }

      if (includePagination) {
        params.PageIndex = state.requestData.page
        params.PageSize = state.requestData.pageSize
      }

      const sortingId = state.requestData.sorting?.id ?? ''

      if (includeSorting && sortingId !== '') {
        params.SortExpression =
          state.requestData.sorting.sortOrder === 'desc'
            ? `${state.requestData.sorting.id} desc`
            : state.requestData.sorting.id
      }

      return qs.stringify(params, {
        arrayFormat: 'comma',
        allowDots: true,
        filter: (prefix, value) => {
          if (
            locale === 'nl' &&
            (prefix.endsWith('.min') || prefix.endsWith('.max')) &&
            value
          ) {
            return String(value).replace('.', ',')
          }

          return value
        },
      })
    }
  },

  getRequestPostData(state) {
    return function () {
      return {
        queryKeyword: state.requestData.searchQuery,
        ...flattenObject(state.requestData.filters, 2),
        ...flattenObject(state.requestData.customParams, 2),
      }
    }
  },

  loadingNewTotals(state) {
    return state.loadingNewTotals
  },

  loadingState(state) {
    return state.loadingState
  },

  loading(state, getters) {
    return getters.loadingState !== possibleLoadStates.DONE
  },

  canFilter(state) {
    return state.config.filterConfigurations.length > 0
  },

  canSearch(state) {
    return state.config.enableKeywordSearch
  },

  searchQuery(state) {
    return state.requestData.searchQuery
  },

  page(state) {
    return state.requestData.page
  },

  pageSize(state) {
    return state.requestData.pageSize
  },

  sorting(state) {
    return state.requestData.sorting
  },

  view(state) {
    return state.requestData.view
  },

  metadata(state) {
    return state.responseData.metadata
  },

  total(state) {
    return state.responseData.pagingData.recordCount
  },

  items(state) {
    return state.responseData.collection
  },

  getCustomParam(state) {
    return function (name) {
      const config = state.config.customParamConfigurations[name]

      assert(
        typeof config === 'object',
        `Missing customParamConfiguration for ${name}`,
      )

      return state.requestData.customParams[name] ?? config.defaultValue
    }
  },

  getItemById(state, getters) {
    return function (id) {
      return getters.items.find((item) => {
        return createObjectHash(item, state.config.rowIdKeys) === id
      })
    }
  },

  sortingOptionsForView(state, getters) {
    return getters.getSortingOptionsForView(state.requestData.view)
  },

  sortingOptions(state) {
    return state.config.sortingOptions
  },

  viewOptions(state) {
    return state.config.viewOptions
  },

  pageCount(state, getters) {
    return Math.ceil(getters.total / state.requestData.pageSize)
  },

  sortingOptionsById(state) {
    return state.config.sortingOptions.reduce((acc, option) => {
      return {
        ...acc,
        [option.id]: option,
      }
    }, {})
  },

  getSortingOptionsForView(state, getters) {
    return function (view) {
      const currentView = state.config.viewOptions.find(
        (item) => item.id === view,
      )

      if (!currentView) {
        return []
      }

      return (currentView.sortingOptions ?? [])
        .map((id) => {
          const option = getters.sortingOptionsById[id]
          assert(option, `Invalid sorting option '${id}' for view '${view}'!`)
          return option
        })
        .filter(Boolean)
    }
  },

  getDefaultSortingForView(state, getters) {
    return function (view) {
      const currentView = state.config.viewOptions.find(
        (item) => item.id === view,
      )

      if (!currentView) {
        return null
      }

      if (currentView.defaultSorting !== undefined) {
        return currentView.defaultSorting
      }

      const [firstSortingOption] = getters.getSortingOptionsForView(view)

      if (!firstSortingOption) {
        return null
      }

      return {
        id: firstSortingOption.id,
        sortOrder: 'asc',
      }
    }
  },

  filterIsApplied(state, getters) {
    return Object.keys(state.requestData.filters).some((key) => {
      return getters.filterHasValue(key)
    })
  },

  filterConfigs(state) {
    return state.config.filterConfigurations
  },

  filterGetDefaultValue(_state, getters) {
    return function (id) {
      const config = getters.filterGetConfig(id)
      if (config.defaultValue === undefined) {
        return config.isMultiValue ? [] : undefined
      }
      return config.defaultValue !== undefined
        ? config.defaultValue
        : config.clearedValue
    }
  },

  filterGetFacetData(state) {
    return function (id) {
      return state.responseData.facets[id]
    }
  },

  filterGetEditorProps(_state, getters) {
    return function (id) {
      const facetData = getters.filterGetFacetData(id)
      const config = getters.filterGetConfig(id)

      return {
        facetData,
        label: config.label,
        value: getters.filterGetValue(id),
        ...(config.editor.props || {}),
      }
    }
  },

  filterGetConfig(_state, getters) {
    return function (id) {
      return getters.filterConfigs.find((filter) => filter.id === id)
    }
  },

  filterGetValue(state) {
    return function (id) {
      return state.requestData.filters[id]
    }
  },

  filterHasValue(_state, getters) {
    return function (id, alternativeValue) {
      const config = getters.filterGetConfig(id)

      let value =
        arguments.length > 1 ? alternativeValue : getters.filterGetValue(id)

      if (config.multiValue && !Array.isArray(value)) {
        value = []
      }

      let hasValue = false

      if (config.multiValue) {
        hasValue = value.length > 0
      } else {
        hasValue = value !== undefined
      }

      if (hasValue && config.clearedValue) {
        if (config.multiValue) {
          assert(
            Array.isArray(config.clearedValue),
            'Cleared value should be an array because filter was marked multiValue',
          )
          hasValue =
            value.length !== config.clearedValue.length ||
            value.some((item, index) => item !== config.clearedValue[index])
        } else {
          hasValue = value !== config.clearedValue
        }
      }

      return hasValue
    }
  },
}
