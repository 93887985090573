export const iconDictionary = new Map([
  ['profile', 'account-circle-line-icon.svg'],
  ['swex', 'SWEX-icon.svg'],
  ['target_blank', 'external-link-line-icon.svg'],
  ['market', 'store-3-line-icon.svg'],
  ['academy', 'school-line-icon.svg'],
  ['lessons', 'graduation-cap-line-icon.svg'],
  ['switch-portfolios', 'arrow-left-right-line.svg'],
  ['workshops', 'pencil-ruler-2-line-icon.svg'],
  ['articles', 'article-line-icon.svg'],
  ['about', 'team-line-icon.svg'],
  ['euro', 'money-euro-circle-line-icon.svg'],
  ['faq', 'question-answer-line-icon.svg'],
  ['portfolio', 'portfolio-icon.svg'],
  ['orders', 'p2p-line-icon.svg'],
  ['balance', 'wallet-line-icon.svg'],
  ['account', 'user-line-icon.svg'],
  ['privacy-on', 'eye.svg'],
  ['privacy-off', 'eye-off.svg'],
  ['logout', 'logout-box-line.svg'],
  ['reading-time', 'time-line-icon.svg'],
  ['calendar', 'calendar-line-icon.svg'],
  ['tag', 'price-tag-3-line-icon.svg'],
  ['location', 'map-pin-2-line-icon.svg'],
  ['edit', 'edit-line-icon.svg'],
  ['anchored', 'portfolio-anchored-icon.svg'],
  ['casked', 'portfolio-casked-icon.svg'],
  ['card-view', 'function-line-icon.svg'],
  ['list-view', 'list-check-2-icon.svg'],
  ['arrow-left', 'arrow-left-s-line-icon.svg'],
  ['arrow-right', 'arrow-right-s-line-icon.svg'],
])
