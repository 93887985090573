
import RemoveIcon from '~/assets/images/remove-icon.svg?inline'
import { getIcon } from '~/utils/get-icon.js'

export default {
  components: {
    RemoveIcon,
  },
  props: {
    variant: {
      type: String,
      default: 'info',
    },
    dismissable: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    customIconName: {
      type: String,
      default: null,
    },
    callToActionLink: {
      type: String,
      default: null,
    },
    callToActionLabel: {
      type: String,
      default: null,
    },
    summarized: {
      type: Boolean,
      default: false,
    },
    summary: {
      type: String,
      default: null,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      expanded: this.isExpanded,
    }
  },
  computed: {
    icon() {
      if (this.customIconName) {
        return getIcon(this.customIconName)
      }

      switch (this.variant) {
        case 'success': {
          return require('~/assets/images/message-success-icon.svg?inline')
        }
        case 'error': {
          return require('~/assets/images/message-error-icon.svg?inline')
        }
        case 'warning': {
          return require('~/assets/images/message-warning-icon.svg?inline')
        }
        case 'info':
        default: {
          return require('~/assets/images/message-info-icon.svg?inline')
        }
      }
    },

    showSlot() {
      return !this.summarized || this.expanded
    },

    showCallToAction() {
      return this.callToActionLabel && this.callToActionLink
    },
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded
    },
  },
}
