import { render, staticRenderFns } from "./w-dialog-layout.vue?vue&type=template&id=38dce40c"
import script from "./w-dialog-layout.vue?vue&type=script&lang=js"
export * from "./w-dialog-layout.vue?vue&type=script&lang=js"
import style0 from "./w-dialog-layout.vue?vue&type=style&index=0&id=38dce40c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WPanel: require('/app/components/global/w-panel/w-panel.vue').default})
