import Vue from 'vue'

import {
  formatAsAmount,
  formatAsPercentage,
  formatAsNumber,
  formatAsDate,
  formatAsCurrency,
  formatAsDateParts,
  formatAsCurrencyParts,
  getFormatterByType,
} from '~/i18n/formatters'

// the old way since it's a computed and not a method as below
if (!Vue.__formatters__) {
  Vue.mixin({
    computed: {
      $currencyChar() {
        return formatAsCurrencyParts(0).find((part) => part.type === 'currency')
          .value
      },
    },
  })

  Vue.__formatters__ = true
}

export default function (_context, inject) {
  inject('formatAsAmount', formatAsAmount)
  inject('formatAsPercentage', formatAsPercentage)
  inject('formatAsNumber', formatAsNumber)
  inject('formatAsDate', formatAsDate)
  inject('formatAsCurrency', formatAsCurrency)
  inject('formatAsDateParts', formatAsDateParts)
  inject('formatAsCurrencyParts', formatAsCurrencyParts)
  inject('getFormatterByType', getFormatterByType)
}
