import { render, staticRenderFns } from "./w-public-page-layout.vue?vue&type=template&id=cf2c365a"
import script from "./w-public-page-layout.vue?vue&type=script&lang=js"
export * from "./w-public-page-layout.vue?vue&type=script&lang=js"
import style0 from "./w-public-page-layout.vue?vue&type=style&index=0&id=cf2c365a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WStack: require('/app/components/global/w-stack/w-stack.vue').default})
