import * as endpoints from '~/api/endpoints'
import * as traderTagEnum from '~/enums/trader-tags'

export const state = () => {
  return {
    loadingState: {
      keyFigures: 'idle',
      keyFiguresInitialLoad: true,
      session: 'idle',
      sessionInitialLoad: true,
    },

    tags: [],

    hasConsented: null,
    hasOffering: null,

    name: {
      firstName: '',
      prefixLastName: '',
      lastName: '',
    },

    portfolio: {
      bottles: 0,
      marketValue: 0,
      purchaseValue: 0,
      roi: 0,
    },
    balance: {
      leverage: 0,
      tradeBalance: 0,
    },
    orders: {
      sellCount: 0,
      buyCount: 0,
    },
    orderlistSummary: {
      countOrders: 0,
      countArticles: 0,
      value: 0,
    },
  }
}

export const mutations = {
  reset(currentState) {
    Object.assign(currentState, state())
  },

  setKeyFiguresLoadingState(state, loadingState) {
    state.loadingState.keyFigures = loadingState
    if (loadingState === 'done') {
      state.loadingState.keyFiguresInitialLoad = false
    }
  },
  setSessionLoadingState(state, loadingState) {
    state.loadingState.session = loadingState
    if (loadingState === 'done') {
      state.loadingState.sessionInitialLoad = false
    }
  },
  setKeyFigures(
    state,
    { portfolio, balance, orders, orderlistSummary, hasConsented, hasOffering },
  ) {
    state.portfolio = portfolio
    state.balance = balance
    state.orders = orders
    state.orderlistSummary = orderlistSummary
    state.hasConsented = hasConsented
    state.hasOffering = hasOffering
  },

  setName(state, { firstName, prefixLastName, lastName }) {
    Object.assign(state.name, { firstName, prefixLastName, lastName })
  },
  setTags(state, tags) {
    state.tags = tags
  },
}

export const actions = {
  clearUserData({ commit }) {
    commit('reset')
  },

  async loadKeyFigures({ commit }) {
    commit('setKeyFiguresLoadingState', 'loading')
    try {
      const keyFigures = await this.$axios.$get(endpoints.getTraderKeyFigures)

      commit('setKeyFiguresLoadingState', 'done')
      commit('setKeyFigures', keyFigures)
      commit('setName', keyFigures)
    } catch (e) {
      if (e.isAxiosError) {
        commit('setKeyFiguresLoadingState', 'error')
      } else {
        throw e
      }
    }
  },

  async loadTags({ commit }) {
    const tags = await this.$axios.$get(endpoints.getTraderTags)

    commit('setTags', tags)
  },
}

export const getters = {
  permissions(_, getters, _rootState, rootGetters) {
    const canDoOnlinePayments = !getters.hasStatusTag(
      traderTagEnum.values.CannotUsePsp,
    )

    const canPlaceBuyOrders = !getters.hasStatusTag(
      traderTagEnum.values.CannotPlaceBuyOrders,
    )

    const canPlaceSellOrders = !getters.hasStatusTag(
      traderTagEnum.values.CannotPlaceSellOrders,
    )

    const isInvestor = rootGetters['auth/isInvestor']

    return {
      canSeePortfolioBid: !isInvestor,
      canSeeBalance: !isInvestor,
      canSeeOrders: !isInvestor,
      canPlaceBuyOrders: !isInvestor && canPlaceBuyOrders,
      canPlaceSellOrders: !isInvestor && canPlaceSellOrders,
      canClaim: !isInvestor,
      canDeposit: !isInvestor && canDoOnlinePayments,
      canWithdraw: !isInvestor && canDoOnlinePayments,
    }
  },

  hasStatusTag(state) {
    return function (tagName) {
      return state.tags.some((item) => item.tag === tagName)
    }
  },

  getMessageForTag(state) {
    return function (tagName) {
      const tag = state.tags.find((item) => item.tag === tagName)
      return tag?.message ?? null
    }
  },

  hasConsented(state) {
    return state.hasConsented
  },

  hasOffering(state) {
    return state.hasOffering
  },

  isLoadingKeyFigures(state) {
    return state.loadingState.keyFigures === 'loading'
  },
}
