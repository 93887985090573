import Vue from 'vue'
import { setLabelsLocale } from '~/i18n'
import { gettext, ngettext, pgettext, npgettext } from '~/i18n/catalog'

export default function ({ $axios, $config, $dayjs, i18n }, inject) {
  const instance = $axios.create()
  instance.setBaseURL('/')

  const state = Vue.observable({
    loading: false,
  })

  $dayjs.locale(i18n.locale)

  // Callback provided by @nuxtjs/i18n module, see Readme for more information
  i18n.onLanguageSwitched = (_oldLocale, newLocale) => {
    changeTranslations(newLocale, { saveSetting: true })

    $dayjs.locale(newLocale)

    i18n.setLocaleCookie(newLocale)
  }

  async function changeTranslations(languageCode) {
    state.loading = true

    const catalogUrl = `${$config.translationsBaseURL}/${languageCode}.json`

    try {
      // should the application crash when we can't switch languages (or should it just fallback to the reference language)
      const catalog = await instance.$get(catalogUrl)

      setLabelsLocale(languageCode, catalog)
    } catch (e) {
      console.error(`Unable to load language file for ${languageCode}`)
    }
    state.loading = false
  }

  inject('gettext', (...args) => gettext(...args))
  inject('ngettext', (...args) => ngettext(...args))
  inject('pgettext', (...args) => pgettext(...args))
  inject('npgettext', (...args) => npgettext(...args))

  inject('i18nTranslations', {
    get isLoading() {
      return state.loading
    },

    async loadTranslations() {
      const languageCode = i18n.getLocaleCookie()

      await changeTranslations(languageCode ?? i18n.defaultLocale)
    },
  })
}

export { gettext }
