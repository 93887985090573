
import { mapGetters, mapState } from 'vuex'
import * as endpoints from '~/api/endpoints'
import * as pspRegistrationStepStatusEnum from '~/enums/psp-registration-step-status'

export default {
  data() {
    return {
      isNotAllowedToDoPayments: false,
      messageContent: null,
    }
  },
  computed: {
    ...mapGetters('trader', ['permissions']),
    ...mapGetters('auth', ['isInvestor', 'isLoggedIn']),
    ...mapState('trader', ['tags']),
    ...mapState('auth', {
      hasToFinishTradingProfile: (state) =>
        !state.session.isTradingProfileCompleted,
      hasToFinishRegistration: (state) =>
        !state.session.isRegistrationCompleted,
      hasToConfirmEmail: (state) => !state.session.isEmailConfirmed,
    }),

    callToActionLabel() {
      if (
        this.messageContent &&
        this.messageContent.status &&
        this.messageContent.status !==
          pspRegistrationStepStatusEnum.values.Required
      ) {
        return this.$gettext('Check your registration status')
      } else {
        return this.$gettext('Complete registration')
      }
    },

    showMessage() {
      return this.isLoggedIn && this.messageContent
    },

    messageVariant() {
      return this.isNotAllowedToDoPayments ? 'warning' : 'info'
    },
  },
  watch: {
    tags() {
      this.determineNextPspRegistrationStep()
    },
  },
  async mounted() {
    this.checkFinishedRegistration()

    if (!this.messageContent) {
      await this.determineNextPspRegistrationStep()
    }
  },
  methods: {
    // Check if the user needs to finish the registration on the platform
    checkFinishedRegistration() {
      if (this.hasToConfirmEmail) {
        this.messageContent = {
          description: this.$gettext(
            'Please confirm your email before you start trading.',
          ),
          link: this.$nuxt.context.app.localePath(
            '/registration/confirm-your-email',
          ),
        }
      } else if (this.hasToFinishRegistration) {
        this.messageContent = {
          description: this.$gettext(
            'We require some additional information before you can start trading.',
          ),
          link: this.$nuxt.context.app.localePath(
            '/registration/start/personal-info',
          ),
        }
      } else if (this.hasToFinishTradingProfile) {
        this.messageContent = {
          description: this.$gettext(
            'We require some additional information before you can start trading.',
          ),
          link: this.$nuxt.context.app.localePath('/registration/finish'),
        }
      }
    },

    // Check if the user needs to finish the registration with the payment service provider
    async determineNextPspRegistrationStep() {
      if (this.isInvestor || this.permissions.canDoOnlinePayments) {
        this.messageContent = null
        return
      }

      // determine the next step that the user has to take
      const steps = await this.$axios
        .$get(endpoints.getPspRegistrationMap)
        .catch((e) => {
          // if the endpoint returns with status 418 the user is not allowed to do online payments
          if (e.response.status === 418) {
            this.isNotAllowedToDoPayments = true
            this.messageContent = {
              description: this.$gettext(
                'You cannot do online payments at this time. Please contact us to find out why',
              ),
              link: null,
            }
          } else if (e.response.status !== 412) {
            // if the endpoint returns with status 412 the user is in a non eu country
            this.messageContent = {
              description: this.$gettext(
                'Your trading profile is not complete, but we are unable to determine its exact status. Please contact our support desk.',
              ),
              link: null,
            }
          }

          return e
        })

      if (Array.isArray(steps) && steps?.length) {
        // show the first step the user has to take to finish the registration with the payment service provider
        this.messageContent = steps[0]
      }
    },
  },
}
