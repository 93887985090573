
import { getExternalUrl } from '~/utils/get-external-url'
import WStack from '../w-stack/w-stack'

export default {
  components: {
    WStack,
  },

  computed: {
    locale() {
      return this.$i18n.locale
    },

    availableLocales() {
      return this.$i18n.locales
    },

    isLoadingLanguage() {
      return this.$i18nTranslations.isLoading
    },

    links() {
      const { locale } = this.$i18n

      return [
        {
          url: getExternalUrl('privacy-statement', locale),
          label: this.$gettext('Privacy Statement'),
        },
        {
          url: getExternalUrl('terms-and-conditions', locale),
          label: this.$gettext('T&C'),
        },
        {
          url: getExternalUrl('cookie-statement', locale),
          label: this.$gettext('Cookie Statement'),
        },
      ]
    },
  },

  methods: {
    onChangeLanguage(locale) {
      const url = this.$nuxt.context.app.switchLocalePath(locale)

      this.$i18n.setLocale(locale)

      this.$router.push(url)
    },
  },
}
