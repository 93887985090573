
import { StructuredText } from 'vue-datocms'
import * as endpoints from '~/api/endpoints'
import { termsAndConditionsQuery } from '~/gql/termsConditions.graphql'

export default {
  components: {
    'datocms-structured-text': StructuredText,
  },

  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      hasScrolledToBottom: false,
      loading: false,
      terms: null,
    }
  },

  async fetch() {
    const variables = {
      locale: this.$i18n.locale,
    }

    const response = await this.$graphql.default.request(
      termsAndConditionsQuery,
      variables,
    )

    this.terms = response.termsAndConditionsModel.text
  },

  methods: {
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        this.hasScrolledToBottom = true
      }
    },
    async onAccept() {
      this.loading = true

      await this.$axios.post(endpoints.getTraderConsentStatus, {
        hasConsented: true,
      })
      this.loading = false
      this.$dialogs.close()

      location.reload()
    },
  },
}
