import { render, staticRenderFns } from "./default.vue?vue&type=template&id=8e2a7c60"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=8e2a7c60&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WInfoDialog: require('/app/components/global/w-info-dialog/w-info-dialog.vue').default,WDialog: require('/app/components/global/w-dialog/w-dialog.vue').default,WToastList: require('/app/components/global/w-toast-list/w-toast-list.vue').default})
